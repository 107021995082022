<template>
  <div class="bulk-grid-wrapper">
    <v-card height="64" class="px-8 mb-4">
      <v-row class="h-full align-center">
        <v-col cols="4" class="pa-0" style="z-index: 10">
          <v-menu offset-y>
            <template #activator="{ on }">
              <span class="caption cursor-pointer filter-text" v-on="on">
                Sort by: {{ activeSortGroup.label }} {{ activeSortItem.label }}
              </span>
            </template>
            <v-list class="pa-0" dense>
              <v-list-item-group
                v-for="sortGroup in sortFilter"
                :key="sortGroup.code"
                v-model="selectedSortValue"
              >
                <v-subheader class="px-4">
                  {{ sortGroup.label }}
                </v-subheader>
                <v-list-item
                  v-for="value in sortGroup.values"
                  :key="value.id"
                  :value="value.id"
                >
                  <v-list-item-content>
                    <span @click="sort(sortGroup, value)">
                      {{ value.label }}
                    </span>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </v-card>
    <v-expansion-panels v-model="expanded" multiple accordion>
      <v-expansion-panel
        v-for="(item, i) in dupeSetsToReview"
        :key="i"
        class="elevation-0 bulk-accordion"
      >
        <v-expansion-panel-header
          color="ap-light-blue-10"
          class="bulk-accordion-header"
        >
          <template #actions>
            <v-icon class="header-icon">$expand</v-icon>
          </template>
          <div class="header-columns">
            {{ item.name }}
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content></v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'BulkGrid',
  data() {
    return {
      expanded: [],
      paginationItemsPerPage: 10,
      activeSortItem: {
        code: 'desc',
        label: 'Descending',
      },
      activeSortGroup: {
        code: 'createdDate',
        label: 'Created Date:',
      },
      selectedSortValue: 'date_desc',
      sortFilter: [
        {
          code: 'name',
          label: 'By Name:',
          values: [
            {
              label: 'A->Z',
              code: 'asc',
              id: 'name_asc',
            },
            {
              label: 'Z->A',
              code: 'desc',
              id: 'name_desc',
            },
          ],
        },
        {
          code: 'createdDate',
          label: 'Created Date:',
          values: [
            {
              label: 'Ascending',
              code: 'asc',
              id: 'date_asc',
            },
            {
              label: 'Descending',
              code: 'desc',
              id: 'date_desc',
            },
          ],
        },
      ],
    }
  },
  computed: {
    ...mapState('dashboard', ['dupeSetsToReview']),
  },
  mounted() {
    this.fetchBulkGridData()
  },
  methods: {
    ...mapActions('dashboard', ['getDupeSetsToReview']),
    sort(filterGroup, filterItem) {
      this.activeSortGroup = filterGroup
      this.activeSortItem = filterItem
      this.fetchBulkGridData()
    },
    async fetchBulkGridData() {
      await this.getDupeSetsToReview({
        perPage: this.paginationItemsPerPage,
        orderByField: this.activeSortGroup.code,
        orderBy: this.activeSortItem.code,
      })
      this.expanded = this.dupeSetsToReview.map((k, i) => i)
    },
  },
}
</script>

<style lang="scss" scoped>
.bulk-grid-wrapper {
  .filter-text {
    color: var(--v-ap-dark-gray-base);
  }
  .bulk-accordion {
    margin-bottom: 10px;
    border: 1px solid #dddbda;
    &::before {
      box-shadow: none;
    }
    &:not(:first-child)::after {
      border-top: none;
    }
    .bulk-accordion-header {
      min-height: 36px;
      max-height: 36px;
      font-weight: 700;
      font-size: 16px;
      padding: 16px 15px;
      color: var(--v-accent-base);
      .header-icon {
        order: 0;
        margin-right: 15px;
      }
      .header-columns {
        order: 1;
      }
    }
  }
}
</style>
